/* coustom */
@media (max-width:1100px) {
  .LeftBox {
    display: none !important;
  }

  .rightBox {
    width: 100% !important;
  }

}

.submitButton {
  width: 343px !important;
  background: linear-gradient(#F37F78, #F75367);
  height: 48px !important;
  color: #FFFFFF !important;
  border-radius: 1000px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  white-space: nowrap;
  text-wrap: nowrap !important;
  text-transform: capitalize !important;
}

.submitButton:disabled{
  opacity: 0.5;
}

.otpContainer {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.otpInput {
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  margin: 0 5px;
  border-radius: 0.5rem;
  border: 1px solid rgba(25, 181, 185, 0.27);
  background: linear-gradient(180deg, #23404B 0%, #19282F 100%) !important;
  color: #FFF;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.otpInput:focus{
  outline: none;
}