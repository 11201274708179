.add-appointment-date-picker .MuiPopover-paper{
   top:50% !important;
   left:50% !important;
   transform: translate(-50%,-50%) !important;
}

.submit-btn{
    background: linear-gradient(180deg, rgba(243, 127, 120, 0.75) 0%, rgba(247, 83, 103, 0.75) 100%);
    width:21.4375rem;
    height: 3rem;
    outline: none;
    border: none;
    color: #FFFFFFBF;
    font-size: 1rem;
    font-weight: 500;
    box-shadow: 0px 0px 20px 0px #0000004D;
    border-radius: 62.5rem;
    margin-top: 2rem;
    cursor: pointer;
}

.submit-btn:disabled{
    cursor: inherit;
    opacity: 0.5;
}

@media screen and (max-width: 480px) {
    .submit-btn{
        width: 15rem;
    }
}